import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Session } from '../types/session.types';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  constructor(private http: HttpClient) {}

  public getAllSessionsForRole(role: string, id: string[]) {
    let params = new HttpParams();
    params = params.append(role, id.join(','));
    return this.http.get<Session[]>(environment.apiUrl + `/sessions/`, { params: params });
  }

  public createSession(session: Session) {
    return this.http.post<Session>(environment.apiUrl + `/sessions/`, { session });
  }

  public getSession(id: string) {
    return this.http.get<Session>(environment.apiUrl + `/sessions/${id}/`);
  }

  public updateSession(session_id: string, session: Partial<Session>) {
    if (session_id) return this.http.patch<Session>(environment.apiUrl + `/sessions/${session_id}/`, session);
  }
}
